'use client';

import React from 'react';
import PostCommentForm from './PostCommentForm';
import useGetCommentsByContentId from '@/hooks/react-query/posts/queries/useGetCommentsByContentId';
import InfiniteScrollContainer from '@/components/InfiniteScrollContainer';
import { ParentTypeEnumType } from '@/types';
import CommentThread from './CommentThread';

export default function CommentSection({
  id,
  slug,
  parentType,
}: {
  id: number;
  slug: string;
  parentType: number;
}) {
  const { data, isFetchingNextPage, hasNextPage, isFetching, fetchNextPage } =
    useGetCommentsByContentId({
      ContentId: id,
      ParentType: parentType as ParentTypeEnumType,
      enabled: true,
    });

  return (
    <InfiniteScrollContainer
      loading={isFetchingNextPage}
      onButtonReached={() => hasNextPage && !isFetching && fetchNextPage()}
      className="pl-4 mt-8"
    >
      <PostCommentForm placeholder="Post your comment" id={id} parentType={parentType} />
      <CommentThread comments={data} postSlug={slug} />
    </InfiniteScrollContainer>
  );
}
