'use client';
import Img from '@/components/atom/img';
import { useRouter } from 'next/navigation';
import React from 'react';

interface CommunityItemProps {
  imageUrl: string;
  title: string;
  membersCount: number;
  slug: string;
  isOwner: boolean;
  isJoined: boolean;
}

const CommunityItem: React.FC<CommunityItemProps> = ({
  imageUrl,
  title,
  membersCount,
  slug,
  isOwner,
  isJoined,
}) => {
  const router = useRouter();
  return (
    <div className="flex items-center justify-between p-2">
      <div className="flex items-center">
        <Img
          src={imageUrl || ''}
          alt={title}
          className="w-10 h-10 border-1 border-white shadow-img rounded-full"
          width={40}
          height={40}
        />
        <div className="ml-3">
          <h4 className="text-xs font-semibold text-gray-brand7">{title}</h4>
          <p className="text-xs text-gray-brand5">{membersCount ?? '0'} members</p>
        </div>
      </div>
      <button
        className="text-purple-brand text-sm font-medium"
        onClick={() => router.push(`/community/${slug}`)}
      >
        {isOwner || isJoined ? 'View' : 'Join'}
      </button>
    </div>
  );
};

export default CommunityItem;
