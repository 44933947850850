import { Skeleton } from '@/components/atom/skeleton';
import React from 'react';

const CommunitySummaryLoader = () => {
  return (
    <div className="flex flex-col flex-grow gap-4">
      <div className="p-6 w-full max-w-[280px] bg-white space-y-6">
        <div className="flex flex-col gap-4">
          <Skeleton className="w-full max-w-[158px] h-[18px] rounded-[68.97px]" />
          <div className="flex items-center gap-2">
            <Skeleton className="w-8 h-8 rounded-full min-w-8" />
            <div className="w-full space-y-1">
              <Skeleton className="w-full h-4" />
              <Skeleton className="w-full max-w-[84px] h-2.5" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Skeleton className="w-8 h-8 rounded-full min-w-8" />
            <div className="w-full space-y-1">
              <Skeleton className="w-full h-4" />
              <Skeleton className="w-full max-w-[84px] h-2.5" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Skeleton className="w-8 h-8 rounded-full min-w-8" />
            <div className="w-full space-y-1">
              <Skeleton className="w-full h-4" />
              <Skeleton className="w-full max-w-[84px] h-2.5" />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <Skeleton className="w-full max-w-[158px] h-[18px] rounded-[68.97px]" />
          <div className="flex items-center gap-2">
            <Skeleton className="w-8 h-8 rounded-full min-w-8" />
            <div className="w-full space-y-1">
              <Skeleton className="w-full h-4" />
              <Skeleton className="w-full max-w-[84px] h-2.5" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Skeleton className="w-8 h-8 rounded-full min-w-8" />
            <div className="w-full space-y-1">
              <Skeleton className="w-full h-4" />
              <Skeleton className="w-full max-w-[84px] h-2.5" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Skeleton className="w-8 h-8 rounded-full min-w-8" />
            <div className="w-full space-y-1">
              <Skeleton className="w-full h-4" />
              <Skeleton className="w-full max-w-[84px] h-2.5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunitySummaryLoader;
