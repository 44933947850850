import React from 'react';
import CommunityItem from './CommunityItem';
import { CommunitySummaryType } from '@/types';

interface CommunityListProps {
  title: string;
  communities: CommunitySummaryType[];
}

const CommunityList: React.FC<CommunityListProps> = ({ title, communities }) => {
  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-xs leading-[18px] uppercase text-gray-brand">{title}</h3>
        <a href="/explore/communities" className="text-orange-brand text-sm font-medium">
          See More
        </a>
      </div>
      {communities.map((community, index) => (
        <CommunityItem
          isOwner={title?.toLowerCase() === 'owned'}
          isJoined={title?.toLowerCase() === 'joined'}
          slug={community.slug}
          key={index}
          imageUrl={community.imageUrl}
          title={community.name}
          membersCount={community.memberCount}
        />
      ))}
    </div>
  );
};

export default CommunityList;
