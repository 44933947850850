'use client';

import Avatar from '@/components/atom/avatar';
import CustomCarouselDot from '@/components/atom/custom-carousel-dot';
import VideoView from '@/components/atom/video-view';
import { PlaySquare } from 'lucide-react';
import React from 'react';
import Carousel from 'react-multi-carousel';
import PostFooter from '../PostFooter';
import CommentSection from '../CommentSection';
import Image from 'next/image';
import { checkMediaTypeByExtension } from '@/lib/utils';
import { ContentTypeEnum } from '@/utils/enums';
import MentionText from '@/components/MentionText';
import { formatRelativeDate } from '@/utils/format-time';
import useToggleLikeMutation from '@/hooks/react-query/posts/mutations/useToggleLikeMutation';
import { useSession } from 'next-auth/react';
interface PostProps {
  postTitle: string;
  username: string;
  timeAgo: string;
  message: string;
  hashtags: string[];
  media: string[];
  onClickPlay?: (src: string) => void;
  data?: any;
}
const Post: React.FC<PostProps> = ({
  username,
  timeAgo,
  message,
  hashtags,
  media,
  onClickPlay,
  postTitle,
  data,
}) => {
  const session = useSession();
  const [showComments, setShowComments] = React.useState(false);
  const mutation = useToggleLikeMutation();
  const handleFooterIconClick = (v: string) => {
    if (v === 'like') {
      mutation.mutate({
        contentId: data.id,
        like: !data.isLiked,
        parentType: ContentTypeEnum.Post,
      });
    }
    if (v === 'share') {
    }
    if (v === 'comment') {
      setShowComments(!showComments);
    }
  };

  return (
    <div className="w-full flex flex-col bg-white p-4 rounded-lg overflow-hidden">
      <div className="w-full h-fit">
        <div className="flex items-center mb-4 gap-2">
          <Avatar fallbackText="C" src="" className="shadow-img" />
          <div>
            <p className="font-medium text-black-brand text-sm">{data?.communityName}</p>
            <p className="text-gray-brand5 text-xs flex items-center gap-2">
              <span>Posted by</span>
              {data?.creator?.handle?.startsWith('@')
                ? data?.creator?.handle
                : `@${data?.creator?.handle}`}{' '}
              <span className="h-1 w-1 rounded-full bg-gray-brand"></span>
              {formatRelativeDate(new Date(timeAgo))}
            </p>
          </div>
        </div>
        <MentionText text={message} />
        <div className="flex flex-wrap mb-4 text-sm">
          {hashtags.map((tag, index) => (
            <span key={index} className="text-blue-500 mr-2">
              #{tag}
            </span>
          ))}
        </div>
        <div className="relative mb-4">
          {media.length === 1 && checkMediaTypeByExtension(media[0]) === 'image' && (
            <div className="w-full h-[248px] md:h-[352px] overflow-hidden">
              <div className="relative w-full h-full">
                <Image
                  src={media[0] || ''}
                  alt="Post media"
                  fill
                  className="object-cover"
                  quality={100} // Ensure best quality rendering
                  crossOrigin="anonymous"
                  priority
                />
              </div>
            </div>
          )}
          {media.length === 1 && checkMediaTypeByExtension(media[0]) === 'video' && (
            <div className="relative">
              <video src={media[0]} className="w-full h-[248px] md:h-[352px] rounded-lg" controls />
              {onClickPlay && (
                <button
                  onClick={() => onClickPlay(media[0])}
                  className="absolute inset-0 flex items-center justify-center text-white text-2xl"
                >
                  <PlaySquare className="text-white" size={50} />
                </button>
              )}
            </div>
          )}
          {media.length > 1 && (
            <div className="relative pb-7">
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 1.5,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1.5,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1.5,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
                customDot={<CustomCarouselDot />}
              >
                {media.map((item, index) => (
                  <div
                    key={index}
                    className="relative px-1 h-[248px] md:h-[352px] w-full flex items-center justify-center"
                  >
                    {checkMediaTypeByExtension(item) === 'video' ? (
                      <VideoView src={item} />
                    ) : (
                      <div
                        className="h-full w-full overflow-hidden"
                        style={{
                          backgroundImage: `url("${item}")`,
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                        }}
                      ></div>
                    )}
                    {checkMediaTypeByExtension(item) === 'video' && onClickPlay && (
                      <button
                        onClick={() => onClickPlay(item)}
                        className="absolute inset-0 flex items-center justify-center text-white text-2xl"
                      >
                        <PlaySquare className="text-white" size={50} />
                      </button>
                    )}
                  </div>
                ))}
              </Carousel>
            </div>
          )}
        </div>
      </div>
      <PostFooter
        data={data}
        hideReply
        hideView
        onClick={handleFooterIconClick}
        likeProcessing={mutation.isPending}
      />

      {showComments && (
        <CommentSection id={data?.id} slug={data?.slug} parentType={ContentTypeEnum.Post} />
      )}
    </div>
  );
};

export default Post;
