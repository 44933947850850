import React from 'react';
import { generateCountArray } from '@/lib/utils';
import { Skeleton } from '@/components/atom/skeleton';

const PostListLoading = () => {
  return generateCountArray(3).map((v) => (
    <div key={v} className="flex flex-col gap-4 p-6 bg-white rounded-lg">
      <div className="flex items-center gap-2 w-full max-w-[277px]">
        <Skeleton className="w-10 h-10 rounded-full min-w-10" />
        <div className="w-full space-y-1">
          <Skeleton className="w-full h-4" />
          <Skeleton className="w-full max-w-[187px] h-3" />
        </div>
      </div>
      <div className="space-y-2">
        {generateCountArray(3).map((n) => (
          <Skeleton key={n} className="w-full h-3" />
        ))}
        <Skeleton className="w-full h-3 max-w-[310px]" />
      </div>
      <div className="flex gap-1 pb-4 overflow-auto">
        <Skeleton className="w-[426px] min-w-[426px] h-[320px] rounded-sm" />
        <Skeleton className="w-[426px] min-w-[426px] h-[320px] rounded-sm" />
      </div>
    </div>
  ));
};

export default PostListLoading;
